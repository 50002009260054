<template>
  <drawer-form
    ref="drawerForm"
    :title="model.id>0?'编辑':'新增'"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">

    <a-form-model-item label='产品' prop="productKey">
      <a-select v-model="model.productKey">
<!--        <a-select-option value=""></a-select-option>-->
        <a-select-option v-for="product in productList" :value="product.productKey">{{product.name}}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label='时长（分钟）' prop="duration">
      <a-input v-model="model.duration"/>
    </a-form-model-item>

    <a-form-model-item label='现价' prop="price">
      <a-input v-model="model.price"/>
    </a-form-model-item>

    <a-form-model-item label='原价' prop="priceDel">
      <a-input v-model="model.priceDel"/>
    </a-form-model-item>

    <a-form-model-item label='推荐' prop="recommend">
      <a-checkbox v-model="model.recommend">显示推荐标识</a-checkbox>
    </a-form-model-item>

  </drawer-form>

</template>
<script>

  export default {
    data() {
      return {
        model: {},

        rules: {
          productKey: [
            {required: true, message: '请选择产品'},
          ],
          duration: [
            {required: true, message: '不能为空'},
          ],
          price: [
            {required: true, message: '不能为空'},
          ],
          priceDel: [
            {required: true, message: '不能为空'},
          ],
          recommend:[]
        },
        loading: false,
        productList:[]

      }
    },

    methods: {
      show(model={}) {
        this.model = JSON.parse(JSON.stringify(model))
        this.loading=false

        if(this.model.id>0){
          this.model.price = this.model.price/100
          this.model.priceDel = this.model.priceDel/100
          this.model = {...this.model}
        }

        this.$get('product/all').then(suc=>{
          this.productList = suc.data
        })

        this.$refs.drawerForm.show()

      },

      hide() {
        this.$refs.drawerForm.hide()
      },

      handleSubmit() {
        if (this.model.id > 0) {
          this.edit()
        } else {
          this.add()
        }
      },
      formatData(){
        return {
          ...this.model,
          price:parseInt(this.model.price*100),
          priceDel:parseInt(this.model.priceDel*100),
        }
      },
      add() {
        this.loading = true
        this.$post('sharingPack', this.formatData()).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },

      edit() {
        this.loading = true
        this.$put('sharingPack', this.formatData() ).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },

    }
  }
</script>
